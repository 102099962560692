<template>
  <div class="review">
    <div class="review__wrapper">
      <img alt="logo" class="review__logo" src="../../assets/logo.png">

      <template v-if="analysis && status">
        <div class="review__title">
          Иммуноглобулины к SARS-COV2 в крови Анализатор иммуноферментный Infinite F50
        </div>

        <table class="review__table">
          <tr>
            <td style="width: 50%">№ заявки</td>
            <td style="width: 50%">{{ analysis.id | number }}</td>
          </tr>
          <tr>
            <td>Лаборатория</td>
            <td>Медикал Форте</td>
          </tr>
          <tr>
            <td>ФИО</td>
            <td>{{ analysis | fullNameRu }}</td>
          </tr>
          <tr>
            <td>Дата рождения</td>
            <td>{{ analysis.client.birthdate | date }}</td>
          </tr>
          <tr>
            <td>Пол</td>
            <td style="text-transform: capitalize;">{{ analysis.client.gender.label }}</td>
          </tr>
          <tr>
            <td>Адрес, страна</td>
            <td>{{ analysis.client.attributes.ru.address }}</td>
          </tr>
          <tr>
            <td>Тип документа</td>
            <td>{{ docType }}</td>
          </tr>
          <tr>
            <td>Номер документа</td>
            <td>{{ docInfo }}</td>
          </tr>
          <tr>
            <td>СНИЛС</td>
            <td>{{ docSnils | snils }}</td>
          </tr>
          <tr class="review__table-tr-bold" v-if="analysis.antibody_g">
            <td>
              Выявление иммуноглобулинов класса G к коронавирусу SARS-COV2 (IgG) в крови ИФА
            </td>
            <td>{{ analysis.antibody_g }}</td>
          </tr>
          <tr class="review__table-tr-bold" v-if="analysis.antibody_gs">
            <td>
              Выявление иммуноглобулинов класса G к коронавирусу SARS-COV2 (IgG) в крови ИФА
            </td>
            <td>{{ analysis.antibody_gs }}</td>
          </tr>
          <tr class="review__table-tr-bold" v-if="analysis.antibody_m">
            <td>
              Выявление иммуноглобулинов класса М к коронавирусу SARS-COV2 (IgМ) в крови ИФА
            </td>
            <td>{{ analysis.antibody_m }}</td>
          </tr>
          <tr>
            <td>Дата заказа</td>
            <td>{{ analysis.order_date | datetime }}</td>
          </tr>
          <tr>
            <td>Дата получения</td>
            <td>{{ analysis.issue_date | datetime }}</td>
          </tr>
        </table>
      </template>

      <div class="review__title" v-if="status === false">
        Срок годности данного результата истек
      </div>
    </div>
  </div>
</template>

<script>
import {qr} from '../../api/analysis/qr'

export default {
  name: "ReviewAntibody",
  data() {
    return {
      analysis: null,
      status: null,
    }
  },
  filters: {
    number: function (value) {
      return value.toString().padStart(7, "0");
    },
    fullNameRu: function (value) {
      let result = '';

      if (value.client.attributes.ru.surname) {
        result += value.client.attributes.ru.surname + ' ';
      }

      if (value.client.attributes.ru.name) {
        result += value.client.attributes.ru.name + ' ';
      }

      if (value.client.attributes.ru.patronymic) {
        result += value.client.attributes.ru.patronymic;
      }

      return result;
    },
  },
  computed: {
    doc() {
      const documents = this.analysis.client.documents.filter(item => item.type.value === this.analysis.doc)

      if (documents && documents.length) {
        return documents[0];
      }

      return null;
    },
    docType() {
      if (this.doc) {
        return this.doc.type.label;
      }

      return null;
    },
    docInfo() {
      if (this.doc) {
        return `${this.doc.series} ${this.doc.number}`;
      }

      return null;
    },
    docSnils() {
      const documents = this.analysis.client.documents.filter(item => item.type.value === 'snils')

      if (documents && documents.length) {
        return documents[0].number;
      }

      return null;
    },
  },
  created() {
    this.getAnalysis();
  },
  methods: {
    getAnalysis() {
      qr(this.$route.params.id, this.$route.query.token, 'antibody')
          .then(response => {
            this.analysis = response.data.data;
            this.status = true;
          })
          .catch(() => this.status = false);
    }
  },
}
</script>

<style lang="scss" scoped>
.review {
  background-color: white;
  margin: 0;
  padding: 24px;
  min-height: 100vh;

  &__wrapper {
    margin: 0 auto;
    justify-content: center;
  }

  &__logo {
    width: 160px;
    margin: 0 auto 5px auto;
  }

  &__title {
    size: 13px;
    text-align: center;
    font-weight: 500;
    color: #1D293F;
    margin: 0 auto 5px auto;
  }

  &__sub-title {
    size: 13px;
    font-weight: 500;
    color: #1D293F;
    margin: 12px auto;
  }

  &__table {
    size: 13px;
    font-weight: 400;
    color: #1D293F;
    width: 100%;

    & td {
      padding: 8px 12px;
      border: 1px solid #D5D9E1;
    }

    &-tr-bold {
      font-weight: 700;
    }

    &-td-bold {
      font-weight: 700;
    }
  }
}
</style>
